















































import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import DateSelect from '@/components/organisms/h/item/date/DateSelect.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  OrderBudgetCreateExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
  dateSelectExtractCondition,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    ItemHierarchyGroup,
    HLine,
    DateSelect
  },
  props: {
    defaultDateValue: {
      type: String as PropType<string>,
      default: () => {
        let tmp = new Date()
        tmp.setDate(tmp.getDate() - 7)
        return tmp.toISOString().split('T')[0]; 
      },
    },
  },
  setup(props, context) {
    /*********************************
     * 表示する選択肢
     *********************************/
    const itemStoreUseList = ref<itemHierarchyUseList>([['Chubunrui']])

    /*********************************
     * データ
     *********************************/
    // 日付
    const extractConditionOfDate = ref<dateSelectExtractCondition>({})
    extractConditionOfDate.value.dateSelect = props.defaultDateValue

    // 商品の絞り込み
    const extractConditionOfItemStore = ref<itemHierarchyGroupExtractCondition>({})

    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const base: OrderBudgetCreateExtractCondition = {
        itemStore: {
          chubunrui: extractConditionOfItemStore.value.chubunrui || [],
          chubunruiCount: extractConditionOfItemStore.value.chubunruiCount || 0,
        },
        dateSelect: extractConditionOfDate.value.dateSelect || ''
      }
      let cond = { ...base }
      //console.log('extract_changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
    }

    const isDateSelected = ref(false)
    const startDay = ref("")
    const endDay = ref("")
    const isFuture = ref(false)
    const updateSelectDate = (params?: string) => {
        //console.log("isDateSelected: " + params)
        if(params  != null){
          isDateSelected.value = true
          var day = new Date( params );
          //console.log("day: " + day.getDay())
          if(day.getDay() != 1){
            let delta = 0
            if( day.getDay() < 1){// 日曜
              delta = 6
            }else{
              delta = day.getDay() - 1
            }
            day.setDate(day.getDate() - delta);
          }
          startDay.value = day.toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit",
          day: "2-digit"}).replaceAll('-', '/') + " (月)"
          day.setDate(day.getDate() +6);
          endDay.value = day.toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit",
          day: "2-digit"}).replaceAll('-', '/') + " (日)"

          let nowd = new Date(Date.now())
          if(day < nowd){
            isFuture.value = false
          }else{// 過去ではない
            isFuture.value = true
          }
        }else{
          isDateSelected.value = false
          isFuture.value = false
        }
    }
    // 遅延初期化
    setTimeout(() => {
      updateSelectDate(extractConditionOfDate.value.dateSelect)
    },100)

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
     const changeExtractConditionOfDate = (
      params: dateSelectExtractCondition
    ) => {
      /* 日付選択の変更 */
      //console.log("changeExtractConditionOfDate")
      extractConditionOfDate.value = params
      updateSelectDate(params.dateSelect)
      changeExtractCondition()
    }
    const changeExtractConditionOfItemStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfItemStore.value = params
      changeExtractCondition()
    }

    const requiredItemInput = ['中分類：常に入力']
    return {
      requiredItemInput,
      itemStoreUseList,
      changeExtractConditionOfItemStore,
      changeExtractConditionOfDate,
      updateSelectDate,
      isDateSelected,
      startDay,
      endDay,
      isFuture
    }
  },
  methods: {
    /*updateSelectDate(params: string) {
        if(params  != null){
          this.isDateSelected = true
        }else{
          this.isDateSelected = false
        }
    }*/
  }
})
