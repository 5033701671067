















import { defineComponent } from '@vue/composition-api'

import PageView from '@/components/templates/h/order-budget/order-budget-create/PageView.vue'
import {
  OrderBudgetCreateExtractCondition,
  OrderBudgetCreateOutputCondition,
  ColumnLabel
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPWeeklyDailyBudgetConfsCountDocument,
} from '@/types/generated/graphql'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_COND_ITEM,
  OUTPUT_COND_STORE,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
  SELECT_MAX_COUNT,
} from '@/utils/constant'
import { EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'
const DEFAULT_OUTPUT_CONST = {
  extractTarget: OUTPUT_EXTRACT_TARGET_DEFAULT, // 出力条件（抽出対象）はデフォルト値を設定できるようにしていないため、定数を用意した
  storeOutput: [OUTPUT_COND_STORE.tanten],
  itemOutput: [OUTPUT_COND_ITEM.budgetGroup],
}

export default defineComponent({
  components: { PageView },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPWeeklyDailyBudgetCreate,
      templateFile: TemplateFile.UiPWeeklyDailyBudgetConf_01,
      extractCondition: {
        itemStore: {
          chubunrui: [],
          chubunruiCount: 0,
        },
        dateSelect: "",
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Store',
          displayItems: DEFAULT_OUTPUT_CONST.storeOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.storeOutput,
          disabledItems: DEFAULT_OUTPUT_CONST.storeOutput,
        },
        {
          outputCondType: 'Item',
          displayItems: DEFAULT_OUTPUT_CONST.itemOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.itemOutput,
          disabledItems: DEFAULT_OUTPUT_CONST.itemOutput,
        },
      ],
      totalCount: null,
      exportSpinner: false,
      isDisable: true,
    }
  },
  methods: {
    changeExtractCondition(cond: OrderBudgetCreateExtractCondition) {
      this.extractCondition = cond
      this.requestCount()
    },
    changeOutputCondition(cond: OrderBudgetCreateOutputCondition) {
      this.outputCondition = cond
      this.requestCount()
    },
    updateState(){
      let cond = this.extractCondition
      if(cond.dateSelect != '' && this.totalCount > 0){
        let referd = new Date( cond.dateSelect )
        if( referd.getDay() > 0){// 最終日(日曜)を取得する
          referd.setDate(referd.getDate() +(7-referd.getDay()) )
        }
        let nowd = new Date(Date.now())
        if(referd < nowd){
          this.isDisable = false
          
        }else{// 未来は無効
          this.isDisable = true
        }
      }else{
        this.isDisable = true
      }
    },
    async requestCount() {
      // 抽出件数の更新
      this.exportSpinner = true
      this.totalCount = null
      console.log("requestCount")
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPWeeklyDailyBudgetConfsCountDocument,
            variables: { where: this._createWhere() },
          })
          const data = result.data.vUiPWeeklyDailyBudgetConfs.totalCount
          this.totalCount = data.toString()
          this.exportSpinner = false
          this.updateState()
        } catch (e) {
          console.log("requestCount error: " + e)
          this.exportSpinner = false
          this.totalCount = '-'
          this.isDisable = true
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
        this.isDisable = true
      }
    },
    /*********************************
     * 以下はエクスポートリクエスト用の処理
     *********************************/
    //　入力チェック
    checkRequired(showToast: boolean) {
      // 入力チェック開始
      let errMsg = []
      if (this.extractCondition.itemStore.chubunrui.length == 0) {
        errMsg.push({
          message: this.$i18n.t('error.requiredItemNotInput'),
        })
      }
      // 選択数上限チェック
      if (this.extractCondition.itemStore.chubunruiCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.chubunrui'),
            selected: this.extractCondition.itemStore.chubunruiCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (errMsg.length) {
        if (showToast) {
          for (const msg of errMsg) {
            Toast.error(this, msg)
          }
        }
        // 入力チェックに引っかかった場合はエクスポートの要求を出さない
        return false
      }
      return true
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      const query = this._createQueryString()
      console.log('requestExport {query}')
      await this.helper.export({
        query,
        jmespathQuery:
        'data.vUiPWeeklyDailyBudgetConfs.edges[].node | map(&merge(`{"__update": null}`, @), @)',
        templateFile: this.templateFile,
        filenameExport: EXPORT_FILENAME.weekly_daily_budget_conf,
        columnLabelMapping: this._create_column_label_mapping(),
      })
    },
    /**
     * ファイル抽出Queryを構築する
     */
     _createQueryString() {
      const where = this._createWhere()
      const rowQuery = `
query {
  vUiPWeeklyDailyBudgetConfs(
    where: ${this.helper.generateQueryWhereString(where)}
    orderBy: ${this._createOrderByString()}
  ) {
    edges {
      node {
        inactiveFlg
        vUiPBudgetGroup{
          uiMCategory1{
            categoryName
          }
          uiMCategory2{
            categoryName
          }
          uiMCategory3{
            categoryName
          }
        }
        budgetGroupId
        storeCd
        weeklyBudget
        weight1
        weight2
        weight3
        weight4
        weight5
        weight6
        weight7
        store {
          storeName
          vUiPWeeklyDailyBudgetConf_Weather {
            rain1
            rain2
            rain3
            rain4
            rain5
            rain6
            rain7
          }
        }
        weatherWeightA
        weatherWeightB
        weatherWeightC
        weatherWeightMax
        configuredFlg
        lastModifiedUserName
        lastModifiedDatetime
        saleDate
        vUiPWeeklyDailyBudgetConf_DowIndex {
          sunIndex
          monIndex
          tueIndex
          wedIndex
          thuIndex
          friIndex
          satIndex
        }
      }
    }
  }
}
`
     return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    /**
     * ファイル抽出QueryのWhereパラメータを構築する
     */
     _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['vUiPBudgetGroup/category0'],
          op: WhereOp.In,
          value: [''],
        },
        { field: ['configuredFlg'], op: WhereOp.In, value: [''] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      setWhereValue(
        where,
        'vUiPBudgetGroup/category0',
        this.extractCondition.itemStore.chubunrui
      )
      setWhereValue(where, 'configuredFlg', this.outputCondition.extractTarget)
      //console.log({ where })
      return where
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
     _createOrderByString() {
      const orderBy: OrderBy[] = [
        {
          field: 'storeCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'budgetGroupId',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
      //console.log({ orderBy })
      return this.helper.generateQueryOrderByString(orderBy)
    },
    _create_column_label_mapping() {
      let tmp = new Date(this.extractCondition.dateSelect)
      let cnt = tmp.getDay()
      if(cnt == 0){// 日曜
        tmp.setDate(tmp.getDate() - 6)
      }else{// 月 - 日
        tmp.setDate(tmp.getDate() - cnt + 1)
      }
      let days = []
      cnt = 0
      while(cnt < 7){
        days.push(tmp.toISOString().split('T')[0])
        tmp.setDate(tmp.getDate() + 1 )
        cnt += 1
      }
      console.log("days: " + days.join(","))
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'vUiPBudgetGroup.uiMCategory1.categoryName',
          to: '',
        },
        {
          from: 'vUiPBudgetGroup.uiMCategory2.categoryName',
          to: '',
        },
        {
          from: 'vUiPBudgetGroup.uiMCategory3.categoryName',
          to: '',
        },
        {
          from: 'budgetGroupId',
          to: '',
        },
        {
          from: 'storeCd',
          to: '',
        },
        {
          from: 'store.storeName',
          to: '',
        },
        {
          from: 'weeklyBudget',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.monIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.tueIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.wedIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.thuIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.friIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.satIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.sunIndex',
          to: '',
        },
        {
          from: 'saleDate',
          to: '',
        },
        {
          from: 'weight1',
          to: '',
        },
        {
          from: 'weight2',
          to: '',
        },
        {
          from: 'weight3',
          to: '',
        },
        {
          from: 'weight4',
          to: '',
        },
        {
          from: 'weight5',
          to: '',
        },
        {
          from: 'weight6',
          to: '',
        },
        {
          from: 'weight7',
          to: '',
        },
        {
          from: 'orderBudget1',
          to: '',
        },
        {
          from: 'orderBudget2',
          to: '',
        },
        {
          from: 'orderBudget3',
          to: '',
        },
        {
          from: 'orderBudget4',
          to: '',
        },
        {
          from: 'orderBudget5',
          to: '',
        },
        {
          from: 'orderBudget6',
          to: '',
        },
        {
          from: 'orderBudget7',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain1',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain2',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain3',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain4',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain5',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain6',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain7',
          to: '',
        },
        {
          from: 'weatherWeight1',
          to: '',
        },
        {
          from: 'weatherWeight2',
          to: '',
        },
        {
          from: 'weatherWeight3',
          to: '',
        },
        {
          from: 'weatherWeight4',
          to: '',
        },
        {
          from: 'weatherWeight5',
          to: '',
        },
        {
          from: 'weatherWeight6',
          to: '',
        },
        {
          from: 'weatherWeight7',
          to: '',
        },
        {
          from: 'weatherWeightA',
          to: '',
        },
        {
          from: 'weatherWeightB',
          to: '',
        },
        {
          from: 'weatherWeightC',
          to: '',
        },
        {
          from: 'weatherWeightMax',
          to: '',
        },
        {
          from: 'configuredFlg',
          to: '',
        },
        {
          from: 'lastModifiedDatetime',
          to: '',
        },
        {
          from: 'lastModifiedUserName',
          to: '',
        },
        {
          from: '_value_dateSelect',
          to: this.extractCondition.dateSelect,
        },
        {
          from: '_value_referDate',
          to: days.join(","),
        },
        {
          from: '_value_category0',
          to: this.extractCondition.itemStore.chubunrui.join(","),
        },
      ]
      console.log("columnLabelMapping")
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
